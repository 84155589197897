import pageheader from "../../common/pageheader";
import servicelistupload from "../servicelistupload";
import VendorServiceListUpload from "../VendorServiceListUpload";
import GroupAssignmentTool from "../GroupAssignmentTool";
import HoursUpdateTool from "../HoursUpdateTool";
import MetadataUpdateTool from "../MetadataUpdateTool";
import utils from "../../../utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "supporttools",
  components: { pageheader, servicelistupload,VendorServiceListUpload,GroupAssignmentTool,HoursUpdateTool,MetadataUpdateTool },
  props: ["id"],
  data() {
    return {
      input: {},
    };
  },
  computed: {
    ...mapGetters(["roles"])
  },
  mounted() {
     // getting input content
    //  Customer_service_list_upload
    // if (this.id == "service_list_upload") {
    //   this.input = utils.getInputContent("serviceListUpload", this.roles);
    // }
     if (this.id == "customer_service_list_upload") {
      this.input = utils.getInputContent("serviceListUpload", this.roles);
    }
    if (this.id == "vendor_service_list_upload") {
      this.input = utils.getInputContent("serviceListUpload", this.roles);
    }
    else if (this.id == "group_assignment") {
      this.input = utils.getInputContent("groupAssignmentTool", this.roles);
    }
    else if(this.id == "hours_update"){
      this.input = utils.getInputContent("hoursUpdateTool", this.roles);
    }
    else if(this.id == "metadata_update"){
      this.input = utils.getInputContent("metadataUpdateTool", this.roles);
    }
  },
  methods: {}
};
