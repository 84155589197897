import { mapGetters } from "vuex";
import pageheader from "../../common/pageheader";
import utils from "../../../utils/utils";
import moment from "moment";
import toolsService from "../../../api/tools.service";
import refresh from "../../secured/SupportToolsDialogs/RefreshButton";
import XLSX from "xlsx";
import UploadDialog from "../../secured/SupportToolsDialogs/UploadDialog";
import LogsDialog from "../../secured/SupportToolsDialogs/LogsDialog";
import ExecuteDialog from "../../secured/SupportToolsDialogs/ExecuteDialog";
export default {
  name: "group-assignment-tool",
  components: { XLSX,pageheader, refresh, UploadDialog, LogsDialog, ExecuteDialog },
  props: [],
  data() {
    return {
      input: {},
      page: 0,
      files: [],
      GroupToolsList: [],
      showUpload: false,
      isLoading: false,
      GroupsListSearch: "",
      apiToken: "",
      customerId: "",
      headers: [
        { text: "S.No", value: "sno", sortable: false },
        { text: "File Name", value: "fileName" },
        { text: "Date Time", value: "startDate" },
        { text: "Status", value: "status" },
        {
          text: "Actions",
          value: "view",
          align: "center",
          width: "240px",
          sortable: false,
        },
      ],
      Logsdialog: false,
      ToolsListExcel: [],
      GroupExcel: [],
      viewLogs: [],
      LogsSearch: "",
      loadingLogs: false,
      fileName: "",
      viewLogheaders: [
        {
          text: "S.No",
          value: "sno",
          align: "center",
          sortable: false,
        },
        { text: "Serial Number", value: "serialNumber" },
        { text: "Name", value: "name" },
        {
          text: "Current GroupName",
          value: "currentGroupName",
        },
        { text: "New Group", value: "newGroup" },
        { text: "Delete Group", value: "deleteGroup" },
        { text: "Is Persisted", value: "isPersisted" },
        { text: "Error Message", value: "errorMessage" },
      ],
      SelectedTool: "",
      selectedId: "",
      fileLoader: false,
      Id: "",
      companyToken: "",
    };
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        const format = "MM-DD-YYYY";
        return moment(value).format(format);
      }
    },
  },
  computed: {
    ...mapGetters(["roles", "userId","customerToken","customerReferenceId","companyName","reportId"]),
  },
  mounted() {
    this.input = utils.getInputContent("groupAssignmentTool", this.roles);
    // if (this.id == "group_assignment") {
    //   this.input = utils.getInputContent("groupAssignmentTool", this.roles);
    // }
    this.Id = this.companyName;
    this.companyToken = this.$store.getters.customerToken;
    this.customerId = this.$store.getters.customerReferenceId;
    this.GetGroupToolsList();
  },
  methods: {
    //Upload Dialog Code
    openUploadDialog() {
      this.files = [];
      this.$refs.uploadModal.showModal();
    },
    closeUploadDialog() {
      this.$refs.uploadModal.closeUploadDialog();
    },
    //get files from child component
    getList(files) {
      this.files = [];
      this.files = files;
    },
    //Upload File
    async UploadFile() {
      this.fileLoader = true;
      let payload = {
        userId: this.userId,
        fileInfo: this.files[0],
      };
      let headerpayload = {
        customerId: this.reportId,
        // apiToken: this.companyToken,
      };
      if (this.files[0] == null) {
        this.$toast.error("Please select a file ");
      } else if (!this.files[0].fileName.includes(".xlsx")) {
        this.$refs.dropzone.removeAllFiles();
        this.$toast.error("please select only files with extension .xlsx");
      } else {
        try {
          // this.selecteditem = this.selectedTitle;
          this.$toast.success("File is being processed. Please wait.");
          this.closeUploadDialog();
          let apiResult = await toolsService.UploadGroupTools(
            payload,
            headerpayload
          );

          if (apiResult.isSuccess == true) {
            this.closeUploadDialog();
            this.fileLoader = false;
            this.$toast.success(apiResult.systemMessage);
            this.GetGroupToolsList();
          }
        } catch (err) {
          this.$toast.error("Unable to upload file" + err);
          this.fileLoader = false;
          this.closeUploadDialog();
        }
      }
    },

    //Get Tools List
    async GetGroupToolsList() {
      this.isLoading = true;
      let payload = {
        customerId: this.reportId,
        // apiToken: this.companyToken,
      };
      try {
        let response = await toolsService.GetGroupTools(payload);
        if (response) {
          this.isLoading = false;
          this.GroupToolsList = [];
          this.GroupToolsList = response.map((d, index) => ({
            ...d,
            sno: index + 1,
            index: false,
          }));
        }
      } catch (err) {
        this.$toast.error("Unable to load the List" + err);
        this.isLoading = false;
      }
    },
    getColor(status) {
      if (status == "Completed") return "green";
      else if (status == "LogsCreated") return "blue";
      else if (status == "ExecutionInProgress") return "#013220";
      else if (status == "InProgress") return "orange";
      else if (status == "Failed") return "red";
      else return "black";
    },
    //Get View Logs
    async openGroupLogsDialog(item) {
      this.$refs.LogModal.showModal();
      this.viewLogs = [];
      this.Logsdialog = true;
      this.loadingLogs = true;
      this.fileName = item.fileName;
      let toolId = item.toolId;
      let headerpayload = {
        customerId: this.reportId,
        // apiToken: this.companyToken,
        toolId: toolId,
      };
      try {
        let response = await toolsService.GetGroupById(toolId, headerpayload);
        if (response) {
          this.loadingLogs = false;
          this.ToolsListExcel = response;
          this.ToolsListExcel = this.ToolsListExcel.map(function (item) {
            delete item.id;
            return item;
          });
          this.viewLogs = response.map((d, index) => ({
            ...d,
            sno: index + 1,
          }));
          this.excelfilename = this.excelfilename + "_Output";
          this.sheetName = this.excelfilename.replace(".xlsx", "");
        }
      } catch (err) {
        this.$toast.error("Unable to Execute" + err);
        this.loadingLogs = false;
      }
    },

    //Execute Logs
    openExecuteDialog(toolId, index) {
      this.$refs.ExecuteModal.showModal();
      this.SelectedTool = toolId;
      this.selectedId = index;
    },
    closeExecuteDialog() {
      this.$refs.ExecuteModal.closeExecuteDialog();
    },
    async ShowExecutedLogs(id, index) {
      var t = this;
      console.log(index);
      let payload = {
        userId: this.userId,
        toolId: id,
        customerId: this.reportId,
      };
      let headerpayload = {
        customerId: this.reportId,
        // apiToken: this.companyToken,
      };
      try {
        let response = await toolsService.ExecuteGroupTools(
          payload,
          headerpayload
        );
        if (response.isSuccess == true) {
          this.$toast.success("Execution in progress!!");
          t.GroupToolsList[index - 1]["index"] = true;
          this.closeExecuteDialog();
        } else {
          t.GroupToolsList[index - 1]["index"] = false;
          this.closeExecuteDialog();
          this.$toast.error(
            "Unable to Execute Please try again after some time"
          );
        }
      } catch (err) {
        this.$toast.error("Unable to Execute" + err);
        this.closeExecuteDialog();
        this.loading = false;
      }
    },

    //Load Excel

    OnGroupExport() {
      this.GroupExcel = [];
      this.GroupExcel.push([
        "Serial Number",
        "Name",
        "Current Group Name",
        "New Group",
        "Delete Group",
        "Is persisted",
        "Error Message",
      ]);
      var ws = XLSX.utils.aoa_to_sheet(this.GroupExcel);
      XLSX.utils.sheet_add_json(ws, this.ToolsListExcel, {
        header: [
          "serialNumber",
          "name",
          "currentGroupName",
          "newGroup",
          "deleteGroup",
          "isPersisted",
          "errorMessage",
        ],
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Group Assignment Logs");
      this.GroupExcel = [];
      XLSX.writeFile(wb, "Group Assignment Logs.xlsx");
    },
  },
};
